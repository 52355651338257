import React from "react";
import { Link } from "react-router-dom";
import "../../Styles/Footer.css";

//Static Assets Import
import brLogo from "../../Assets/brand-logo.png";
import phIco from "../../Assets/phone.png";
import locIco from "../../Assets/location.png";
import mailIco from "../../Assets/mail.png";
import desSq from "../../Assets/twin-sq.png";

const Footer = () => {
  return (
    <footer>
      <div className="footer-main">
        <div className="foot-brand">
          <img src={brLogo} alt="" className="brLogo" />
          <div className="brand-caption">
            PGR Builders, a trusted name in the construction industry and as a
            premier construction company, we take pride in our commitment to
            excellence and our ability to turn your visions into reality.
          </div>
        </div>
        <div className="foot-contact">
          <div className="foot-detail">
            <img
              src={mailIco}
              alt=""
              className="foot-ico"
              style={{ width: "25px", height: "20px" }}
            />
            <div
              className="foot-value"
              style={{ cursor: "pointer" }}
              onClick={() => (window.location = "mailto:pgrbuilders@gmail.com")}
            >
              pgrbuilders@gmail.com
            </div>
          </div>
          <div
            className="foot-detail"
            style={{fontFamily: "sans-serif" }}
          >
            <img src={phIco} alt="" className="foot-ico" />
            <div
              className="foot-value"
              style={{ cursor: "pointer", fontFamily: "unset" }}
              onClick={() => (window.location = "tel:+919089444999")}
            >
              +91 90892 22999 <br/>+91 90894 44999
            </div>
          </div>
          <div className="foot-detail">
            <img
              src={locIco}
              alt=""
              className="foot-ico"
              style={{ width: "20px", height: "25px" }}
            />
            <div className="foot-value">
              PGR Builders Flat No. 103,First Floor PGR Orchid, Hyderguda,
              Rajendranagar,Hyderabad - 500 048.
            </div>
          </div>
        </div>
        <div className="foot-nav">
          <div className="sep-50">
            <div className="foot-nav-label">
              <img src={desSq} alt="" className="twin-sq" /> Quick Links
            </div>
            <ul>
              <li>
                <Link
                  to="/"
                  onClick={() => {
                    window.scrollTo(0, 0);
                  }}
                >
                  Home
                </Link>
              </li>
              <li>
                <Link to="/ongoing-projects">Ongoing Projects</Link>
              </li>
              <li>
                <Link to="/prev-projects">Previous Projects</Link>
              </li>
              <li>
                <Link to="/contact-us">Contact Us</Link>
              </li>
            </ul>
          </div>
          <div className="sep-50 sep-hidden">
            <div className="foot-nav-label">
              <img src={desSq} alt="" className="twin-sq" /> Resources
            </div>
            <ul>
              <li>Terms & Conditions</li>
              <li>Privacy Policy</li>
            </ul>
          </div>
        </div>
      </div>
      <div className="footer-bar">
        <div className="foot-legal">
          <Link to="">Terms & Conditions</Link>
          <Link to="">Privacy Policy</Link>
        </div>
        <div className="foot-copy">
          &copy; Copyright PGR Builders. All Rights Reserved 2023
        </div>
      </div>
    </footer>
  );
};

export default Footer;
