import React, { useEffect } from "react";
import HeroHeaderAlt from "../HomePage/HeroHeaderAlt";
import heroImg from "../../Assets/home-hero-3.jpg";
import OnProjectCard from "./OnProjectCard";
import data from "./OngoingData.json";
const OngoingProjects = () => {
  useEffect(()=>{
    window.scrollTo(0, 0);
  })
  return (
    <>
      <HeroHeaderAlt
        heroImg={heroImg}
        title="Ongoing Projects"
        caption="Track PGR's builiding journey as it Happens , Track Live Progress and Commencements of you beloved projects or to measure our record. We have it all crystal clear"
        hasId={true}
      />
      {data.map((prev, index) => {
        return (
          <OnProjectCard
            title={prev.title}
            description={prev.description}
            download={prev.download}
            image={prev.image}
            tags={prev.tags}
            key={index}
            link={prev.link}
          />
        );
      })}
    </>
  );
};

export default OngoingProjects;
