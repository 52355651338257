import React from "react";
import "../../Styles/AboutUs.css";
import AboutImg from "../../Assets/about-us.png";
const AboutUs = () => {
  return (
    <section className="about-section">
      <h1 className="about-heading">About Us</h1>
      <div className="about-info">
        <img src={AboutImg} alt="" className="about-img" />
        <div className="about-text">
          <p>
            PGR Builders, a trusted name in the construction industry and
            as a premier construction company, we take pride in our commitment
            to excellence and our ability to turn your visions into reality.
          </p>
          <p>
            At PGR Builders, we believe that every project is unique and
            should reflect the distinct needs and preferences of our clients.
            Our team of skilled architects, engineers, and builders work closely
            with you to understand your requirements, ensuring that every detail
            is meticulously incorporated into the design and construction
            process.
          </p>
          <hr />
          {/* <p>
            Choosing PGR Builders means choosing a partner who is dedicated
            to making your construction experience seamless and stress-free.
            Join our growing list of satisfied clients and let us transform your
            ideas into a reality that surpasses your imagination. Our aim is to
            not only meet but exceed your expectations, delivering your dream
            space on time and within budget.
          </p> */}
          <p>
            Contact us today to embark on your construction journey with PGR
            Constructions, where expertise, experience, and excellence meet.
          </p>
        </div>
      </div>
      <div className="about-stats">
        <hr />
        <div className="about-stat">
          <div>20</div>
          <span>Years experience</span>
        </div>
        <hr />
        <div className="about-stat">
          <div>2</div>
          <span>Ongoing Projects</span>
        </div>
        <hr />
        <div className="about-stat">
          <div>15</div>
          <span>Successful Projects</span>
        </div>
      </div>
    </section>
  );
};

export default AboutUs;
