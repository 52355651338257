import React,{useEffect} from 'react'
import HeroHeaderAlt from '../HomePage/HeroHeaderAlt'
import OffProjectCard from './OffProjectCard'
import data from './PrevData.json'
import heroImg from '../../Assets/home-hero.jpg'
const PrevProjects = () => {
    useEffect(() => {
      window.scrollTo(0, 0);
    });
  return (
    <>
      <HeroHeaderAlt
        heroImg={heroImg}
        title="Previous Projects"
        caption="Welcome to the journey we have made so far to reach your eyes , Here you can see what PGR has done to reach where it is today and What makes us your best option to go with"
        hasId={true}
      />
      {data.map((prev,index)=>{
        return(
          <OffProjectCard 
            title={prev.title}
            description={prev.description}
            download={prev.download}
            image={prev.image}
            tags={prev.tags} 
            key={index}/>
        );
      })}
    </>
  );
}

export default PrevProjects