import React from "react";
import badge from '../../Assets/badge.png';
import "../../Styles/Highlights.css";
const Highlights = ({highlights}) => {
  return (
    <section className="highlight-section">
      <div className="highlight-info">
        <h1 className="highlight-heading">Project Highlights</h1>
        <div className="highlight-caption">
          Here are some key features that make PGR Projects standout in the hoarded market of contractors
        </div>
      </div>
      <div className="highlight-cards">
        {highlights.map((highlight,index)=>{
          return (
            <div className="highlight-card" key={index}>
              <img src={badge} alt="" className="card-badge" />
              <h2 className="highlight-value">{highlight}</h2>
            </div>
          );
        })}
      </div>
    </section>
  );
};

export default Highlights;
