import React from "react";
import "../../Styles/OtherList.css";
const Amenities = ({am_description,amenities}) => {
  return (
    <section className="other-list">
      <h1 className="other-heading">Amenities</h1>
      <div className="other-cards">
        {amenities.map((am , index)=>{
          return (
            <div className="other-card" key={index}>
              <img src={am.visual} alt="" className="project-thumb" />
              <h2 className="project-name">{am.title}</h2>
            </div>
          );
        })}
      </div>
      <p className="am-description">
        {am_description}
      </p>
    </section>
  );
};

export default Amenities;
