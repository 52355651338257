import React ,{useEffect, useState} from 'react';
import '../../Styles/ImageCarousel.css'
import aLeft from '../../Assets/a-left.png';
import aRight from '../../Assets/a-right.png';
const ImageCarousel = ({imgArray}) => {
  const [index, setIndex] = useState(0);
  // Add the Image as Variable in this Array by Importing
  const length = imgArray.length;

  const handlePrevious = () => {
    const newIndex = index - 1;
    setIndex(newIndex < 0 ? length - 1 : newIndex);
  };
  const handleNext = () => {
    const newIndex = index + 1;
    setIndex(newIndex >= length ? 0 : newIndex);
  };

  useEffect(() => {
    const timer = setTimeout(() => {
      handleNext();
    }, 5000);
    return () => clearTimeout(timer);
  });
  return (
    <section className="carousel-container">
      <img
        src={aLeft}
        alt=""
        className="carousel-back"
        onClick={() => {
          handlePrevious();
        }}
      />
      <img src={imgArray[index]} alt="" className="carousel-image" />
      <div className="indicators">
        {imgArray.map((i, k) => {
          return (
            <div
              className={`indicator-dot ${k === index ? "dot-selected" : ""}`}
              key={k}
            ></div>
          );
        })}
      </div>
      <img
        src={aRight}
        alt=""
        className="carousel-next"
        onClick={() => {
          handleNext();
        }}
      />
    </section>
  );
}

export default ImageCarousel