import React from 'react'
import '../../Styles/SubHero.css'
const SubHero = ({title ,description ,download, glimpse}) => {
  const hyperLink = () =>{
    window.location.href = download;
  }
  return (
    <section className="subhero-section">
      <div className="status-description">
        <h2 className="other-heading">
          {title}
        </h2>
        <p className="status">
          {description}
        </p>
        <button className="brochure-btn" onClick={()=>{hyperLink()}}>Download Brochure</button>
      </div>
      <img src={glimpse} alt="" className="subhero-img" />
    </section>
  );
}

export default SubHero