import React from "react";
import "./App.css";
import Homepage from "./Components/HomePage/HomPage";
import Footer from "./Components/Common/Footer"
import Header from "./Components/Common/Header";
import { Route, Routes } from "react-router-dom";
import ProjectDetails from "./Components/ProjectDetails/ProjectDetails";
import ContactUs from "./Components/ContactUs/ContactUs";
import PrevProjects from "./Components/PrevProjects/PrevProjects";
import OngoingProjects from "./Components/OngoingProjects/OngoingProjects";
function App() {
  return (
    <>
      <Header />
      <Routes>
        <Route path="/" element={<Homepage />} />
        <Route path="/ongoing-projects" element={<OngoingProjects/>} />
        <Route path="/ongoing-projects/:index" element={<ProjectDetails/>} />
        <Route path="/prev-projects" element={<PrevProjects/>} />
        <Route path="/prev-projects/:index" element={<ProjectDetails/>} />
        <Route path="/contact-us" element={<ContactUs/>} />
        <Route path="*" element={<div>Oops ! The Page You Are Looking For is not found , Contact Admin!</div>}/>
      </Routes>
      <Footer />
    </>
  );
}

export default App;
