import React from "react";
import "../../Styles/ChooseUs.css";
import data from "./Reason.json";
import secure from "../../Assets/secure.png";
const ChooseUs = () => {
  return (
    <section className="choose-section">
      <h1 className="c-section-heading">Why Choose Us?</h1>
      <p className="c-section-caption">
        We at PGR believe in core Ethics , Here are few reasons for what we are
        known in market for
      </p>
      <div className="choose-cards">
        {data.map((r, index) => {
          return (
            <div className="choose-card" key={index}>
              <img src={secure} alt="" className="choose-logo" />
              <h3>{r.reason}</h3>
              <div className="choose-reason">{r.backing}</div>
            </div>
          );
        })}
      </div>
    </section>
  );
};

export default ChooseUs;
