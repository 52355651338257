import React from "react";
import "../../Styles/Layouts.css";
const Layouts = ({layouts}) => {
  return (
    <section className="layouts-container">
      <h1 className="layouts-heading">Site Layouts</h1>
      <div className="layouts-visuals">
        {layouts.map((lay,ind)=>{
          return <img src={lay} alt="" className="layout-card" key={ind} />;
        })}
      </div>
    </section>
  );
};

export default Layouts;
