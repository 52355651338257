import React , {useEffect} from "react";
import { useParams } from "react-router-dom";
import ImageCarousel from "./ImageCarousel";
import OtherList from "./OtherList";
import Amenities from "./Amenities";
import Highlights from "./Highlights";
import Layouts from "./Layouts";
import ProjectStatus from "./ProjectStatus";
import SubHero from "./SubHero";
import ProjectData from './ProjectDetails.json';
import PreviousData from '../PrevProjects/PrevData.json'

const ProjectDetails = () => {
  const {index} = useParams(); // Project Selection Arry ID from the DataJson
  const masterKey = ProjectData[index];
  console.log(masterKey);
    useEffect(() => {
      window.scrollTo(0, 0);
    });
  if(masterKey===undefined){
    return (
      <h1>
        Oops ! The Page You Are Looking For is not found , Contact Admin!
      </h1>
    );
  }else{
  return (
    <>
      <ImageCarousel imgArray={masterKey.carousel_images}/>
      <SubHero title={masterKey.project_title} description={masterKey.project_description} glimpse={masterKey.project_glimpse} download={masterKey.download}/>
      <Highlights highlights={masterKey.project_highlights} />
      <Amenities am_description={masterKey.am_description} amenities={masterKey.amenities} />
      <Layouts layouts={masterKey.site_layouts}/>
      <ProjectStatus download={masterKey.download} description={masterKey.project_description}/>
      <OtherList data={PreviousData}/>
    </>
  )};
};

export default ProjectDetails;
