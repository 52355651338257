import React from "react";
import "../../Styles/ProjectStatus.css";
import logo from "../../Assets/brand-logo.png";
import status from "../../Assets/status.png";
import status1 from "../../Assets/status2.png";
import status2 from "../../Assets/status1.png";
import status3 from "../../Assets/status3.png";
const ProjectStatus = ({description,download}) => {
  const hyperLink =()=>{
    window.location.href =download;
  }
  return (
    <section className="status-section">
      <h1 className="other-heading">
        Project Status<span>*As of today</span>
      </h1>
      <div className="status-container">
        <div className="status-description">
          <img src={logo} alt="" className="company-logo" />
          <p className="status">
            {description}
          </p>
          <button className="brochure-btn" onClick={()=>{hyperLink()}}>Download Brochure</button>
        </div>
        <div className="status-visuals">
          <div className="status-card">
            <img src={status} alt="" className="status-image" />
            {/* <h2 className="status-caption">Project 1</h2> */}
          </div>
          <div className="status-card">
            <img src={status1} alt="" className="status-image" />
            {/* <h2 className="status-caption">Project 1</h2> */}
          </div>
          <div className="status-card">
            <img src={status2} alt="" className="status-image" />
            {/* <h2 className="status-caption">Project 1</h2> */}
          </div>
          <div className="status-card">
            <img src={status3} alt="" className="status-image" />
            {/* <h2 className="status-caption">Project 1</h2> */}
          </div>
        </div>
      </div>
    </section>
  );
};

export default ProjectStatus;
