import React, { useState } from "react";
import "../../Styles/ContactForm.css";
import phIco from "../../Assets/phone.png";
import mailIco from "../../Assets/mail.png";
const ContactForm = () => {
  const [message, setMessage] = useState("");
  const [f_name, setFname] = useState("");
  const [l_name, setLname] = useState("");
  const [phone, setPhone] = useState("");
  const [email, setEmail] = useState("");
  const jData = {
    name: f_name,
    l_name: l_name,
    email: email,
    phone: phone,
    message: message,
  };
  const saveNote = () => {
    if (message ==="" || f_name ==="" || l_name==="" || phone==="" || email === "") {
      alert(
        "Please Fill complete details and then click send, So we can serve you better!"
      );
    } else {
      var xhr = new XMLHttpRequest();
      xhr.open("POST", "https://api.pgrbuilders.com/contact-users");
      xhr.setRequestHeader("X-Requested-With", "XMLHttpRequest");
      xhr.setRequestHeader('Access-Control-Allow-Methods','GET, POST')
      xhr.setRequestHeader("Access-Control-Allow-Origin", "*");
      xhr.setRequestHeader("Content-Type", "application/json");
      xhr.send(JSON.stringify(jData));
      document.getElementById("f_name").value = null;
      document.getElementById("l_name").value = null;
      document.getElementById("phone").value = null;
      document.getElementById("message").value = null;
      document.getElementById("email").value = null;
      document.getElementById("contact-form").style.display = "none";
      document.getElementById("contact-filled").style.display = "block";
      setEmail(null);
      setFname(null);
      setLname(null);
      setMessage(null);
      setPhone(null);
    }
  };
  return (
    <section className="form-section">
      <h1 className="section-heading">Get in Touch</h1>
      <p className="section-caption">
        Let's Get in touch . Please fill this form and we'll be at your service
        as soon as possible
      </p>
      <div className="form-container">
        <div className="form-info">
          <span className="info-line">
            Fill out the form and we’ll get back to you soon.
          </span>
          <div className="form-detail">
            <div
              className="foot-value"
              style={{ cursor: "pointer" }}
              onClick={() => (window.location = "mailto:pgrbuilders@gmail.com")}
            >
              <img
                src={mailIco}
                alt=""
                className="foot-ico"
                style={{
                  width: "25px",
                  height: "20px",
                  verticalAlign: "middle",
                }}
              />
              pgrbuilders@gmail.com
            </div>
            <div
              className="foot-value"
              style={{ cursor: "pointer" , fontFamily: 'sans-serif' }}
              onClick={() => (window.location = "tel:+919089222999")}
            >
              <img
                src={phIco}
                alt=""
                className="foot-ico"
                style={{ verticalAlign: "middle" }}
              />
              +91 90892 22999 , +91 90894 44999
            </div>
          </div>
        </div>
        <div
          className="done-form"
          id="contact-filled"
          style={{ display: "none", textAlign: "center" }}
        >
          <div className="circle">
            <div className="checkmark"></div>
          </div>
          Your Details are received successfully , We'll get back to you shortly
        </div>
        <div className="form-sheet d-flex-col" id="contact-form">
          <div className="form-row">
            <div className="form-input">
              <div className="form-label">First Name</div>
              <input
                type="text"
                className="form-value"
                placeholder="Enter your name"
                id="f_name"
                onChange={(e) => {
                  setFname(e.target.value);
                }}
              />
            </div>
            <div className="form-input">
              <div className="form-label">Last Name</div>
              <input
                type="text"
                className="form-value"
                placeholder="Enter your family name"
                id="l_name"
                onChange={(e) => {
                  setLname(e.target.value);
                }}
              />
            </div>
          </div>
          <div className="form-row">
            <div className="form-input">
              <div className="form-label">e-Mail</div>
              <input
                type="text"
                className="form-value"
                placeholder="Enter your email address"
                id="email"
                onChange={(e) => {
                  setEmail(e.target.value);
                }}
              />
            </div>
            <div className="form-input">
              <div className="form-label">Phone</div>
              <input
                type="text"
                className="form-value"
                placeholder="Enter your phone number"
                id="phone"
                onChange={(e) => {
                  setPhone(e.target.value);
                }}
              />
            </div>
          </div>
          <div className="form-input" style={{ width: "100%" }}>
            <div className="form-label">Message</div>
            <input
              type="text"
              className="form-value"
              placeholder="Write a message"
              id="message"
              onChange={(e) => {
                setMessage(e.target.value);
              }}
            />
          </div>
          <div className="form-row">
            <button
              className="form-send"
              onClick={() => {
                saveNote();
              }}
            >
              SEND
            </button>
          </div>
        </div>
      </div>
    </section>
  );
};

export default ContactForm;
