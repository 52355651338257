import React from 'react'
import '../../Styles/OtherList.css'
const OtherList = ({data}) => {
  return (
    <section className="other-list">
      <h1 className="other-heading">Other Projects</h1>
      <div className="other-cards">
        <div className="other-card">
          <img src={data[0].image} alt="" className="project-thumb" />
          <h2 className="project-name">{data[0].title}</h2>
          <div className="project-tags">
            <span className="tags">{data[0].tags[0]}</span>
            <span className="tags">{data[0].tags[1]}</span>
          </div>
        </div>
        <div className="other-card">
          <img src={data[1].image} alt="" className="project-thumb" />
          <h2 className="project-name">{data[1].title}</h2>
          <div className="project-tags">
            <span className="tags">{data[1].tags[0]}</span>
            <span className="tags">{data[1].tags[1]}</span>
          </div>
        </div>
        <div className="other-card">
          <img src={data[2].image} alt="" className="project-thumb" />
          <h2 className="project-name">{data[2].title}</h2>
          <div className="project-tags">
            <span className="tags">{data[2].tags[0]}</span>
            <span className="tags">{data[2].tags[1]}</span>
          </div>
        </div>
      </div>
    </section>
  );
}

export default OtherList