import React from "react";
import "../../Styles/HeroHeader.css";
import { useLocation } from "react-router-dom";
const HeroHeader = ({ heroImg, title, caption , hasId }) => {
  const location = useLocation();
  const pathname = location.pathname;
  const handleClick=(e)=>{
    e.preventDefault();
    hasId?
    window.location.replace("#contact-form"):
    window.location.replace("/contact-us");
  }
  return (
    <section className="hero-header">
      <img src={heroImg} alt="" className="hero-img" />
      <div className="hero-heading">
        <h2 className="hero-title">{title}</h2>
        <span className="hero-caption">{caption}</span>
        <button onClick={(e) => {handleClick(e)}} className="hero-btn" id="know-more" style={{display: pathname==='/contact-us'? 'none':'block'}}>
          Know More
        </button>
      </div>
    </section>
  );
};

export default HeroHeader;
