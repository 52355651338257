import React , {useState,useEffect} from "react";
import logo from '../../Assets/brand-logo.png';
import ham from '../../Assets/ham-ico.svg';
import { useLocation, Link } from "react-router-dom";
import '../../Styles/Header.css';

const Header = () => {
  const location = useLocation();
  const pathname = location.pathname;
  const [showMenu , setShowMenu] = useState(false);
  const handleMenu=()=>{showMenu?setShowMenu(false):setShowMenu(true)}
  useEffect(()=>{
    window.scrollTo(0, 0);
  })
  return (
    <header>
      <Link to="/" className="brand-section">
        <img src={logo} alt="" className="brand-logo" />
      </Link>
      <div className={`${showMenu ? "ham-nav-links" : "nav-links"}`}>
        <Link
          onClick={(e) => {
            handleMenu();
          }}
          to="/"
          className={`nav-link ${pathname === "/" ? "link-active" : ""}`}
        >
          HOME 
        </Link>
        <Link
          onClick={(e) => {
            handleMenu();
          }}
          to="/ongoing-projects"
          className={`nav-link ${
            pathname.includes("/ongoing-projects") ? "link-active" : ""
          }`}
        >
          ONGOING PROJECTS
        </Link>
        <Link
          onClick={(e) => {
            handleMenu();
          }}
          to="/prev-projects"
          className={`nav-link ${
            pathname.includes("/prev-projects") ? "link-active" : ""
          }`}
        >
          PREVIOUS PROJECTS
        </Link>
        <Link
          onClick={(e) => {
            handleMenu();
          }}
          to="/contact-us"
          className={`nav-link ${
            pathname === "/contact-us" ? "link-active" : ""
          }`}
        >
          CONTACT US
        </Link>
      </div>
      <div
        className="ham-menu"
        onClick={(e) => {
          handleMenu();
        }}
      >
        <img src={ham} alt="Hamburger Icon" className="ham-ico" />
      </div>
    </header>
  );
};

export default Header;
