import React , {useEffect} from 'react'
import HeroHeader from '../Common/HeroHeader'
import heroImg from '../../Assets/contact-hero.jpg'
import ContactForm from '../Common/ContactForm';
const ContactUs = () => {
    useEffect(() => {
      window.scrollTo(0, 0);
    });
  return (
    <>
      <HeroHeader
        heroImg={heroImg}
        title="Contact Us"
        caption="Whether it is a whole lot of discussion or you want a quick detail check, We are ready to answer all your curious questions"
      />
      <ContactForm/>
    </>
  );
}

export default ContactUs