import React from "react";
import "../../Styles/ProjectPages.css";
import SqBx from "../../Assets/sqbx.png";
const OffProjectCard = ({title,tags,image,description,download}) => {
  return (
    <div className="project-card">
      <img src={image} alt="" className="project-img" />
      <div className="project-info">
        <h1 className="project-title">{title}</h1>
        <div className="project-tags">
            {/* {tags.map((tg, index)=>{
                return(<span className="tags" key={index}>{tg}</span>);
            })} */}
        </div>
        <div className="project-details">
          {description}
        </div>
      </div>
      <div className="project-card-actions">
        <img src={SqBx} alt="" className="project-des" />
        <button className="project-action" onClick={(e)=>{window.location.href = download}}>DOWNLOAD BROCHURE</button>
      </div>
    </div>
  );
};

export default OffProjectCard;
