import React from "react";
import HeroHeaderAlt from "./HeroHeaderAlt";
import ContactForm from "../Common/ContactForm";
import ChooseUs from "./ChooseUs";
import AboutUs from "./AboutUs";
import heroImg from "../../Assets/home-hero.jpg";
const HomPage = () => {
  return (
    <>
      <HeroHeaderAlt
        heroImg={heroImg}
        title="Ongoing Project"
        caption="The destination of precious living.PGR Elira at Attapur, is a premium collection of residences in 2 and 3 BHK format crafted for your family to nurture life in its more delightful forms. Planned in 3.9 Gunta ib here every square foot will reflect the happy vibes of a blooming vast community. With three blocks each one has units in varied sizes"
        hasId={false}
        download={"https://pgrbuilders.com/ongoing-projects/0"}
        isHome={true}
      />
      <AboutUs />
      <ChooseUs />
      <ContactForm />
    </>
  );
};

export default HomPage;
