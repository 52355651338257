import React from "react";
import "../../Styles/HeroHeader.css";
const HeroHeaderAlt = ({ heroImg, title, caption,download,hasId,isHome}) => {
  const handleClick = (e) => {
    e.preventDefault();
    window.location.href = download;
  };
  return (
    <section className="hero-header">
      <img src={heroImg} alt="" className="hero-img" />
      <div className={`hero-heading ${isHome? "hero-heading-ext" :""}`}>
        {!isHome? <h1 className="hero-title">{title}</h1>: 
        <> 
        <h1 className="hero-title">PGR Builders</h1> 
        <h6 className="hero-subtitle">{title}</h6>
        </>}
        <span className="hero-caption">{caption}</span>
        <button
          className="hero-btn"
          id="know-more"
          style={{ display: hasId ? "none" : "block" }}
          onClick={(e) => {
            handleClick(e);
          }}
        >
          Know More
        </button>
      </div>
    </section>
  );
};

export default HeroHeaderAlt;
